import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import PageList from "../PageList";
import mboLogo from "../../assets/logo.svg";

export default class Header extends Component {
  render() {
    return (
      <div className="top-navbar-container px-1 px-lg-0 pl-lg-5">
        <Navbar expand="lg" variant="dark">
          <Navbar.Brand href="/">
            <div className="logo"><img src={mboLogo} alt="MBO regio Zwolle"/></div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className=" mt-5 mt-lg-0 ml-lg-5 bg-darkblue rotate-row-inverted">
            <Nav className="ml-5 mr-auto nav-height">
              <PageList menu="PRIMARY" />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
