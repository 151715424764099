import React, { Component } from "react";

export default class FullPageSpinner extends Component {
  render() {
    return (
      <div className={`d-flex h-100 ${this.props.disableMargin ? "" : "my-5"}`}>
        <div className="container align-self-center text-center">
          <div className="spinner-border big-spinner" role="status">
            <span className="sr-only">Laden...</span>
          </div>
        </div>
      </div>
    );
  }
}
