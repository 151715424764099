import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { NavItem, NavDropdown } from "react-bootstrap";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import { LinkContainer } from "react-router-bootstrap";
import FullPageSpinner from "../FullPageSpinner";
/**
 * List of pages in navbar
 */
const GET_PAGES_QUERY = gql`
  query MENU_ITEMS($first: Int, $location: MenuLocationEnum) {
    menuItems(first: $first, where: { location: $location }) {
      nodes {
        key: id
        parentId
        title: label
        url
        path
        cssClasses
      }
    }
  }
`;

export default function PageList(props) {
  const [activeKey, setActiveKey] = useState();
  const { loading, error, data } = useQuery(GET_PAGES_QUERY, {
    variables: { location: props.menu, first: 100 },
  });

  if (loading) return <FullPageSpinner disableMargin={true} />;
  if (error) return <p>Error :(</p>;

  const flatListToHierarchical = (
    data = [],
    { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
  ) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
      const newItem = { ...item };
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
      childrenOf[id] = childrenOf[id] || [];
      newItem[childrenKey] = childrenOf[id];
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem);
    });
    return tree;
  };

  let menuItems = flatListToHierarchical(data.menuItems.nodes);

  return menuItems.map((item, key) => {
    if (typeof item.children !== "undefined" && item.children.length > 0) {
      return (
        <React.Fragment key={key}>
          <NavDropdownMenu
            key={key}
            title={decodeURI(item.title)}
            id="collasible-nav-dropdown"
            className={`font-weight-bold pr-md-3 ${
              key === activeKey || window.location.pathname.includes(item.path)
                ? "active"
                : ""
            }`}
          >
            {item.children.map((navItem, index) => {
              return (
                <LinkContainer
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={navItem.path}
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setActiveKey(key);
                    }}
                  >
                    <span
                      className={"font-weight-bold"}
                      dangerouslySetInnerHTML={{ __html: navItem.title }}
                    ></span>
                  </NavDropdown.Item>
                </LinkContainer>
              );
            })}
          </NavDropdownMenu>
        </React.Fragment>
      );
    } else {
      return (
        <NavItem
          className="nav-item"
          key={key}
          onClick={() => {
            setActiveKey(key);
          }}
        >
          <NavLink
            className={`font-weight-bold nav-link pr-md-3 ${item.cssClasses}`}
            key={item.key}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={item.path}
          >
            {item.title}
          </NavLink>
        </NavItem>
      );
    }
  });
}
