import React, { Component, Link } from "react";
import zoneLogo from "./assets/zone.svg";
import landstedeLogo from "./assets/landstede.svg";
import cibapLogo from "./assets/cibap.svg";
import mmaLogo from "./assets/mma.svg";
import deltionLogo from "./assets/deltion.svg";

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="row my-3 d-none d-lg-flex">
              <div className="col d-flex align-items-center">
                <a
                  href="https://zonecollege.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={zoneLogo} />
                </a>
              </div>
              <div className="col d-flex align-items-center">
                <a
                  href="https://www.landstedembo.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={landstedeLogo} />
                </a>
              </div>
              <div className="col d-flex align-items-center">
                <a
                  href="https://www.cibap.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={cibapLogo} />
                </a>
              </div>
              <div className="col d-flex align-items-center">
                <a
                  href="https://www.mensoaltingzwolle.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={mmaLogo} />
                </a>
              </div>
              <div className="col d-flex align-items-center">
                <a
                  href="https://www.deltion.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={deltionLogo} />
                </a>
              </div>
            </div>
            <div className="row my-3 d-lg-none">
              <div className="col-sm-12 text-center mb-5">
                <a
                  href="https://zonecollege.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={zoneLogo} />
                </a>
              </div>
              <div className="col-sm-12 text-center mb-5">
                <a
                  href="https://www.landstedembo.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={landstedeLogo} />
                </a>
              </div>
              <div className="col-sm-12 text-center mb-5">
                <a
                  href="https://www.cibap.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={cibapLogo} />
                </a>
              </div>
              <div className="col-sm-12 text-center mb-5">
                <a
                  href="https://www.mensoaltingzwolle.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={mmaLogo} />
                </a>
              </div>
              <div className="col-sm-12 text-center mb-5">
                <a
                  href="https://www.deltion.nl/"
                  target="_blank"
                  className="mx-auto"
                >
                  <img className="img-fluid" src={deltionLogo} />
                </a>
              </div>
            </div>
          </div>
          <div className="container-fluid blocks-image text-white text-center">Bekijk onze <a class='text-white' Style={"text-decoration:underline"} href="/cookieverklaring/">cookieverklaring</a></div>
        </footer>
      </>
    );
  }
}
