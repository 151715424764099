import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRouter from "./components/AppRouter";
import {BrowserRouter as Router, Link, useLocation} from "react-router-dom";
import CookieConsent, {Cookies} from 'react-cookie-consent';
import TagManager from 'react-gtm-module'

function usePageViews() {
  let location = useLocation();
  /*React.useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);*/
}

export function PageViews() {
  usePageViews();
  return <span />;
}

const tagManagerArgs = {
    gtmId: 'GTM-N3NT86Q'
}

if(Cookies.get('gatsby-gdpr-google-tagmanager') == 'true')
{
    TagManager.initialize(tagManagerArgs);
}
function App() {
  //const trackingId = ""; // Replace with your Google Analytics tracking ID
  //ReactGA.initialize(trackingId);

  return (
    <Router>
      <Header />
      <AppRouter />
      <PageViews />
      <Footer />
        <CookieConsent
            location="bottom"
            buttonText={"Accepteer"}
            declineButtonText={"Afwijzen"}
            enableDeclineButton={true}
            style={{ background: "#1a1a1a", borderTop: "1px solid rgb(2, 102, 197)", alignItems: "center", zIndex:1000}}
            buttonStyle={{ color: "#ffffff", borderRadius: "20px", background: "#6cc24a", fontSize: "13px" }}
            declineButtonStyle={{ color: "#ffffff", borderRadius: "20px", background: "#ff9700", fontSize: "13px" }}
            cookieName="gatsby-gdpr-google-tagmanager"
            onAccept={() => {Cookies.set("gatsby-gdpr-google-tagmanager", "true"); TagManager.initialize(tagManagerArgs)}}>
            <div><span style={{fontSize:"12px"}}>Door het gebruik van cookies kunnen wij het gebruik van onze website
            optimaliseren, zien hoe bezoekers zoals jij de website gebruiken en op
            basis daarvan de website verbeteren.
                <br/>Bekijk onze <Link to={"/cookieverklaring/"}>cookieverklaring</Link></span></div>


        </CookieConsent>
    </Router>
  );
}

export default App;
