import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import FullPageSpinner from "../components/FullPageSpinner";

/**
 * Home Page
 */
const GET_PAGES_QUERY = gql`
  query GET_PAGE_BY_URI($uri: ID!) {
    page(id: $uri, idType: URI) {
      id
      databaseId
      title
      date
      uri
      headerFields {
        title
        subtitle
        backgroundImage {
          sourceUrl
          altText
        }
      }
      homepageContent {
        fieldGroupName
        blocks {
          text
          url
          image {
            sourceUrl
            altText
          }
          backgroundColour
        }
      }
    }
  }
`;

export default function HomePage() {
  const { loading, error, data } = useQuery(GET_PAGES_QUERY, {
    variables: { uri: "/" },
  });

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  const icons = [];

  for (var i = 1; i <= 10; i++) {
    icons.push(<span key={i} className={`icon-${i}`}></span>);
  }

  return (
    <div className="container-fluid m-0 home">
      <div className="icon-container">{icons}</div>
      <div className="row pt-5">
        <div className="container home-header">
          <div className="row">
            <div className="col-12  text-center">
              <h1
                className="text-uppercase text-white"
                dangerouslySetInnerHTML={{
                  __html: data.page.headerFields.title,
                }}
              ></h1>
              <div
                className="text-white px-md-5 mx-md-5"
                dangerouslySetInnerHTML={{
                  __html: data.page.headerFields.subtitle,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {data.page.homepageContent.blocks ? (
        <div className="row pt-5 square-background">
          <div className="container-fluid block-background">
            <div className="container pt-5 ">
              <div className="row pt-5">
                {data.page.homepageContent.blocks.map((item, key) => {
                  let textPosition = [
                    "",
                    "left-bottom",
                    "right-top",
                    "right-bottom",
                  ][key % 4];

                  return (
                    <div
                      key={key}
                      id={key}
                      className={
                        "col-12 col-md-6 homepage-block block-margin-" +
                        key +
                        (key === 4 ? " offset-md-2" : "") +
                        (key === 7 ? " offset-md-4" : "")
                      }
                    >
                      {key === 4 && (
                        <>
                          <div className="floating-text floating-text-left floating-text-left-4">
                            <h2>Oriënteer je op</h2>
                            <h2>een interessegebied en</h2>
                            <h2>verdiep je daarna</h2>
                            <h2>in de opleidingen</h2>
                            <h2 className="text-yellow">
                              die daarbinnen vallen.
                            </h2>
                          </div>
                          <div className="floating-text floating-text-right">
                            <h2>Hoe kom ik</h2>
                            <h2>tot een</h2>
                            <h2 className="text-yellow">goede keuze?</h2>
                          </div>
                        </>
                      )}
                      {key === 7 && (
                        <>
                          <div className="floating-text floating-text-left">
                            <h2>Praat met je mentor,</h2>
                            <h2>met je ouders</h2>
                            <h2>en met leerlingen</h2>
                            <h2 className="text-yellow">die al een keuze</h2>
                            <h2 className="text-yellow">hebben gemaakt.</h2>
                          </div>
                          <div className="floating-text floating-text-right">
                            <h2>Er is altijd</h2>
                            <h2 className="text-yellow">een opleiding</h2>
                            <h2>die bij</h2>
                            <h2>jou past!</h2>
                          </div>
                        </>
                      )}
                      <Link to={item.url}>
                        {(key === 4 || key === 7) && (
                          <div className="icon-container">
                            <span className="icon-1"></span>
                            <span className="icon-2"></span>
                            <span className="icon-3"></span>
                            <span className="icon-4"></span>
                          </div>
                        )}
                        <div
                          className={
                            "image-holder bg-after-" + item.backgroundColour
                          }
                        >
                          <img
                            src={item.image.sourceUrl}
                            alt={item.image.altText}
                          />
                          <div
                            className={`text-overlay ${textPosition}`}
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
