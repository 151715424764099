import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import FullPageSpinner from "./../components/FullPageSpinner";

/**
 * Default Page
 */
const GET_PAGE_QUERY = gql`
  query GET_PAGE_BY_URI($uri: ID!) {
    page(id: $uri, idType: URI) {
      id
      databaseId
      title
      date
      uri
      headerFields {
        title
        subtitle
        backgroundImage {
          sourceUrl
          altText
        }
      }
      pageContent {
        type
        pagetitle
        content
        blocks {
          content
          backgroundColour
        }
      }
    }
  }
`;

export default function Page() {
  const location = useLocation();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: location.pathname },
  });

  if (loading) return <FullPageSpinner />;
  if (error || !data.page) return <NotFound />;

  return (
    <>
      <div className="container-fluid page square-background repeat">
        <div className="row pt-5">
          <div className="container page-header">
            <div className="row">
              <div className="col-12 text-center">
                <div className="image-holder mx-auto">
                  {data.page.headerFields.backgroundImage?.sourceUrl ? (
                    <div>
                      <div className="position-relative mx-auto fit-content">
                        <img
                          src={data.page.headerFields.backgroundImage.sourceUrl}
                        />
                        <h1
                          className="text-white"
                          dangerouslySetInnerHTML={{
                            __html: data.page.headerFields.title,
                          }}
                        ></h1>
                      </div>
                    </div>
                  ) : (
                    <h1
                      className="text-white"
                      dangerouslySetInnerHTML={{
                        __html: data.page.headerFields.title,
                      }}
                    ></h1>
                  )}
                </div>
                {data.page.headerFields.subtitle && (
                  <div
                    className="text-banner"
                    dangerouslySetInnerHTML={{
                      __html: data.page.headerFields.subtitle,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-5 mt-3">
          {data.page.pageContent.type === "text" && (
            <div className="row bg-white page-content star p-lg-5">
              <div className="col-12 p-3 px-4 p-lg-5 px-lg-7">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.page.pageContent.content,
                  }}
                />
              </div>
            </div>
          )}
          {data.page.pageContent.type === "blocks" && (
            <>
              <div className="row bg-white page-content mb-5">
                <div className="col-12 p-5">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: data.page.pageContent.pagetitle,
                    }}
                  />
                </div>
              </div>
              <div className="row row-blocks">
                <div className="col-sm-12 col-md-6">
                  {data.page?.pageContent?.blocks?.map((block, index) => {
                    if (index % 2 === 0)
                      return (
                        <div className={`col-sm-12 mb-5 px-0`}>
                          <div
                            className={`page-content p-3 p-lg-5 px-lg-6 mx-md-3 bg-white`}
                            dangerouslySetInnerHTML={{ __html: block.content }}
                          ></div>
                        </div>
                      );
                  })}
                </div>
                <div className="col-sm-12 col-md-6">
                  {data.page?.pageContent?.blocks?.map((block, index) => {
                    if (index % 2 === 1)
                      return (
                        <div className={`col-sm-12 mb-5 px-0`}>
                          <div
                            className={`page-content p-3 p-lg-5 px-lg-6 mx-md-3 bg-white`}
                            dangerouslySetInnerHTML={{ __html: block.content }}
                          ></div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </>
          )}
          {data.page.pageContent.type === "3-blocks" && (
            <>
              <div className="row three-block-row">
                {data.page?.pageContent?.blocks?.map((block) => (
                  <div className={`col-sm-12`}>
                    <div
                      className={`page-content p-3 p-lg-5 px-lg-8 mx-md-3 h-100 bg-${
                        block.backgroundColour ?? "white"
                      }`}
                      dangerouslySetInnerHTML={{ __html: block.content }}
                    ></div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
