import React from "react";
import { Link } from "react-router-dom";

/**
 * Not Found Page
 */
export default function NotFound() {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="text-white">Error 404 &mdash; Niet gevonden</h1>
          <hr />
          <p className="text-white">
            Deze pagina waar je naar opzoek was bestaat niet (meer). Ga terug
            naar de{" "}
            <u>
              <Link className="text-white" to="/">
                homepagina
              </Link>
            </u>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
